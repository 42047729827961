<template>
  <b-card v-if="data" no-body class="card-statistics p-1" style="min-height: 200px;">
    <div class="d-flex justify-content-between">
      <h4 class="font-weight-bolder">Course Record - CPT</h4>
      <b-card-text class="update-text font-small-2 mr-25 mb-0">
        <!-- Updated 1 day ago -->
      </b-card-text>
    </div>
    <b-card-body class="">
      <b-row class="info-wrapper">
        <b-col
          v-for="(item, index) in data"
          :key="'course' + index"
          xl="3"
          :sm="index < data.length - 1 ? 6 : ''"
          :class="[index < data.length - 1 ? 'mb-2' : '', item.customClass]"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48">
                <feather-icon
                  size="24"
                  :icon="item.icon"
                  :style="'color:' + item.color"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto color0">
              <h4 class="font-weight-bolder mb-0" :style="'color:' + item.color">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0" :style="'color:' + item.color">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      // data: [
      //   {
      //     customClass: "",
      //     icon: "ClockIcon",
      //     color: "#64A4B4",
      //     title: "80 hrs",
      //     subtitle: "Total hours",
      //   },
      //   {
      //     customClass: "",
      //     icon: "ClockIcon",
      //     color: "#013E79",
      //     title: "20 hrs",
      //     subtitle: "Outstanding CPT hours",
      //   },
      //   {
      //     customClass: "",
      //     icon: "AlertTriangleIcon",
      //     color: "#01573E",
      //     title: "FAIL",
      //     subtitle: "6 staff have not fulfilled the required hours",
      //   },
      // ],
    };
  },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>
<style lang="scss" scoped>
.info-wrapper {
  position: absolute;
  width: calc(100% - 3rem);
  bottom: 40px;
}
.update-text {
  color: #828282;
}
// @media screen and (max-width:1200px){

// }
@media screen and (max-width: 1200px) {
  .info-wrapper {
    position: relative;
    width: 100%;
    bottom: 0px;
  }
}
</style>
