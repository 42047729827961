<template>
  <b-card no-body class="card-client">
    <b-card-header>
      <b-card-title class="font-weight-bolder w-100 mb-2">{{ data.title }}</b-card-title>
      <b-card-text class="mb-0 chart-result">
        {{ data.num }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="client-body">
      <vue-apex-charts id="budget-chart" height="50" :options="chartOptions" :series="data.series" :key="data.num" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "area",
          toolbar: { show: false },
          zoom: { enabled: false },
          sparkline: { enabled: true },
        },
        grid: {
          padding: {
            top: 3,
          },
        },
        colors: [this.data.lineColor],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },

        tooltip: {
          enabled: true,
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.card .card-header .card-title {
  overflow: hidden;
  white-space: nowrap;
}
</style>
