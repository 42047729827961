<template>
  <b-card no-body class="card-appointment">
    <b-card-body v-if="data.length > 0" class="appointment-body">
      <!-- <p style="font-size: 8px;position:absolute;top:0px;right:5px;cursor:pointer;padding: 5px;"
        :to="{ name: 'todo-tomorrow-details' }"><u>See all</u></p> -->
      <b-button
        v-if="ability.can('read', 'actionlist')"
        style="
          font-size: 10px;
          position: absolute;
          top: 0px;
          right: 5px;
          cursor: pointer;
          background: none;
          border: none;
        "
        variant="light"
        :to="{ name: 'todo-tomorrow-details' }"
      >
        <span class="text-nowrap">
          <!-- <feather-icon size="14" icon="EyeIcon" /> -->
          <u>See all</u>
        </span>
      </b-button>
      <b-row class="flex-column">
        <b-col
          v-for="(item, index) in data"
          :key="item.index"
          xl="12"
          :class="[index < data.length - 1 ? 'mb-2' : '', item.customClass]"
        >
          <b-media no-body>
            <b-media-aside class="mr-0 align-self-center">
              <b-avatar size="48" rounded :class="item.color">
                <feather-icon size="24" class="color4" icon="MailIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto d-flex align-items-center">
              <!-- <div class="date">
                <b-card-text class="mb-0">
                  {{ dayNames[new Date().getDay() + 1] }}
                </b-card-text>
                <b-card-text class="font-weight-bolder mb-0 color0">
                  {{ new Date().getDate() + 1 }}
                </b-card-text>
              </div> -->
              <div class="separate"></div>
              <div class="meeting">
                <b-card-text class="font-weight-bolder mb-0 color0">
                  <!-- {{ item.meeting.id }} - {{ item.meeting.name }} -->
                  {{ item.name }}
                </b-card-text>
                <b-card-text class="mb-0">
                  <!-- {{ item.meeting.content }} -->
                  {{ item.count }}
                </b-card-text>
              </div>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>

    <b-card-body v-else-if="bdaydata.length > 0" class="appointment-body">
      <b-row class="flex-column">
        <b-col>
          <b-media no-body>
            <!-- <b-media-aside class="mr-2 align-self-center">
              <b-avatar size="48">
                <feather-icon size="24" class="color4" icon="CalendarIcon" />
              </b-avatar>
            </b-media-aside> -->

            <b-media-body class="my-auto d-flex align-items-center">
              <div class="date">
                <b-card-text class="mb-0">
                  <h3 class="color4">
                    Upcoming Birthdays - {{ monthNames[new Date().getMonth()] }}
                  </h3>
                </b-card-text>
              </div>
              <!-- <div class="separate"></div> -->
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
      <b-row class="d-flex flex-column">
        <b-col md="6" v-if="bdaydata.length > 0">
          <div class="meeting" v-for="client in bdaydata">
            ({{ client.id }}) {{ client.name_cn }} - {{ client.date_of_birth }}
          </div>
          <br />
        </b-col>
        <b-col v-else>No clients birthday this month.</b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import store from "@/store";
import {
  BCard,
  BCardHeader,
  BButton,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import ability from "@/libs/acl/ability";
import { data } from "vue-echarts";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      dayNames: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      data: [
        // {
        //   customClass: "",
        //   icon: "MailIcon",
        //   color: "color4",
        //   time: {
        //     weekday: "THU",
        //     date: "24"
        //   },
        //   meeting: {
        //     id: "900290",
        //     name: "Wong Chi Kin",
        //     content: "JAL_Standing Instruction Renewal (Label)"
        //   }
        // },
        // {
        //   customClass: "",
        //   icon: "MailIcon",
        //   color: "color4",
        //   time: {
        //     weekday: "THU",
        //     date: "24"
        //   },
        //   meeting: {
        //     id: "900290",
        //     name: "Wong Chi Kin",
        //     content: "JAL_Standing Instruction Renewal (Label)"
        //   }
        // },
        // {
        //   customClass: "",
        //   icon: "MailIcon",
        //   color: "color4",
        //   time: {
        //     weekday: "THU",
        //     date: "24"
        //   },
        //   meeting: {
        //     id: "900290",
        //     name: "Wong Chi Kin",
        //     content: "JAL_Standing Instruction Renewal (Label)"
        //   }
        // },
      ],
      bdaydata: [],
      // clientTask: 'All Clients Actions Completed for upcoming week.',
      // userTask: 'All Users Actions Completed for upcoming week.',
    };
  },
  methods: {
    fetchDashboardToDo() {
      store
        .dispatch("app/fetchDashboardToDo")
        .then((response) => {
          console.log(response);
          if (response.data.summary) {
            this.data = response.data.summary.slice(0, 3);
            // this.data.forEach(action => {
            //   if (action.name == 'Professional Investor Reminder' || action.name == 'Standing Authority Renewal' || )
            // });
          } else {
            this.bdaydata = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openAllTodo() {},
  },
  created() {
    this.fetchDashboardToDo();
  },
  setup() {
    return {
      ability,
    };
  },
  // props: {
  //   data: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.card-appointment {
  overflow: auto;

  // max-height:230px;
  .date {
    .card-text {
      text-align: center;
      font-size: 1rem;
    }
  }

  .separate {
    height: 40px;
    width: 1px;
    margin: 0 20px;
    background: #000;
  }

  // .card-body{
  //     padding: 2rem 3rem;
  // }
}
</style>
