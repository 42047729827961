<template>
  <b-card no-body class="card-client">
    <b-row class="match-height">
      <b-col xl="6" sm="6">
        <b-card-header>
          <b-card-title class="font-weight-bolder w-100 mb-2">{{
            data.title
          }}</b-card-title>
          <div>
            <b-card-text class="mb-2 chart-result">
              {{ data.num }}
            </b-card-text>
            <!-- <p class="color0">{{ data.text.p1 }}</p>
            <p class="color0">{{ data.text.p2 }}</p> -->
          </div>
        </b-card-header>
      </b-col>
      <b-col xl="6" sm="6">
        <b-card-body class="client-body">
          <vue-apex-charts
            id="bank-ac-chart"
            :options="chartOptions"
            :series="data.series"
            :key="data.num"
          />
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
    },
    chartOptions: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped></style>
