<template>
  <b-card no-body class="">
    <b-card-header>
      <b-card-title class="font-weight-bolder w-100 pl-1">{{ data.title }}</b-card-title>
    </b-card-header>
    <b-card-body class="pb-0">
      <vue-apex-charts
        id="budget-chart"
        height="345"
        :options="chartOptions"
        :series="data.series"
        :key="data.num"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    VueApexCharts,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: { show: false },
          zoom: { enabled: false },
          // sparkline: { enabled: true },
        },
        // title:{ text:this.data.title },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetX: 0,
          offsetY: 0,
          markers: {
            radius: 100,
            // offsetX: 50,
            // width: 12,
            // height: 12,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            // endingShape: 'rounded'
          },
        },
        colors: ["#64A4B4", "#94C8BB"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: ["Previous Month", "Current Month", "Upcoming Month"],
          labels: {
            style: {
              colors: ["#BDBDBD", "#BDBDBD", "#BDBDBD"],
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ["#BDBDBD"],
            },
          },
        },
        fill: {
          opacity: 1,
        },

        // stroke: {
        //     curve: 'smooth'
        // },

        // tooltip: {
        //     enabled: false,
        // },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.card-header {
  padding: 0rem 1.5rem;
  height: 4.9rem;
}
// .card .card-header + .card-content > .card-body:first-of-type, .card .card-header + .card-body{
//     padding-top:2.2rem
// }
// @media screen {

// }
</style>
